<script>
import { required } from "vuelidate/lib/validators";

import {
    authMethods,
    authFackMethods,
    notificationMethods,
} from "@/state/helpers";
import { BASE_URL } from "../../common";
import alertMixin from "../../../mixins/alertMixin";
export default {
    mixins: [alertMixin],
    data() {
        return {
            email: "",
            password: "",
            submitted: false,
        };
    },
    computed: {
        notification() {
            return this.$store ? this.$store.state.notification : null;
        },
    },
    mounted() {
        window.localStorage.clear();
    },
    created() {
        document.body.classList.add("auth-body-bg");
    },
    validations: {
        email: { required },
        password: { required },
    },
    methods: {
        ...authMethods,
        ...authFackMethods,
        ...notificationMethods,

        tryToLogIn() {
            this.submitted = true;
            // stop here if form is invalid
            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            } else {
                let loader = this.$loading.show({
                    container: this.fullPage ? null : this.$refs.formContainer,
                    canCancel: false,
                    onCancel: this.onCancel,
                });
                const payload = {
                    username: this.email,
                    password: this.password,
                };
                const options = {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(payload),
                };
                fetch(`${BASE_URL}auth/login`, options)
                    .then((res) => {
                        return res.json();
                    })
                    .then((res) => {
                        loader.hide();
                        if (res.status_code == 404) {
                            this.showError("Invalid credentials");
                            return;
                        }
                        this.tryingToLogIn = false;
                        this.isAuthError = false;
                        this.$store.dispatch(
                            "apidata/fetchRequests",
                            res.token
                        );
                        localStorage.setItem("cs_user_token", res.token);
                        localStorage.setItem("cs_user_role", res.group);

                        this.$router.push(
                            this.$route.query.redirectFrom || {
                                name: "home",
                            }
                        );
                        this.$store.dispatch(
                            "apidata/fetchUserInfo",
                            res.token
                        );
                    });
            }
        },
        redirect(url) {
            window.location.href = url;
        },
    },
};
</script>

<template>
    <div>
        <div>
            <div class="container-fluid p-0">
                <div class="row no-gutters">
                    <div class="col-lg-4">
                        <div
                            class="authentication-page-content p-4 d-flex align-items-center min-vh-100"
                        >
                            <div class="w-100">
                                <div class="row justify-content-center">
                                    <div class="col-lg-9">
                                        <div>
                                            <div class="text-center">
                                                <div>
                                                    <a href="/" class="logo">
                                                        <img
                                                            src="@/assets/images/fcw-bilang.png"
                                                            height="40"
                                                            alt="logo"
                                                            style="margin-right=35px"
                                                        />
                                                    </a>
                                                </div>

                                                <h4 class="font-size-18 mt-3">
                                                    Customer Service Department
                                                </h4>
                                                <p class="text-muted">
                                                    Sign in to Customer service
                                                    Portal
                                                </p>
                                            </div>

                                            <b-alert
                                                variant="danger"
                                                class="mt-3"
                                                v-if="notification.message"
                                                show
                                                dismissible
                                                >{{
                                                    notification.message
                                                }}</b-alert
                                            >

                                            <div class="p-2">
                                                <form
                                                    class="form-horizontal"
                                                    @submit.prevent="tryToLogIn"
                                                >
                                                    <div
                                                        class="form-group auth-form-group-custom mb-4"
                                                    >
                                                        <i
                                                            class="ri-user-6-fill auti-custom-input-icon"
                                                        ></i>
                                                        <label for="email"
                                                            >Username</label
                                                        >
                                                        <input
                                                            type="text"
                                                            v-model="email"
                                                            class="form-control"
                                                            id="email"
                                                            placeholder="Enter username"
                                                            :class="{
                                                                'is-invalid':
                                                                    submitted &&
                                                                    $v.email
                                                                        .$error,
                                                            }"
                                                        />
                                                        <div
                                                            v-if="
                                                                submitted &&
                                                                $v.email.$error
                                                            "
                                                            class="invalid-feedback"
                                                        >
                                                            <span
                                                                v-if="
                                                                    !$v.email
                                                                        .required
                                                                "
                                                            >
                                                                User name is
                                                                required.
                                                            </span>
                                                        </div>
                                                    </div>

                                                    <div
                                                        class="form-group auth-form-group-custom mb-4"
                                                    >
                                                        <i
                                                            class="ri-lock-2-line auti-custom-input-icon"
                                                        ></i>
                                                        <label
                                                            for="userpassword"
                                                            >Password</label
                                                        >
                                                        <input
                                                            v-model="password"
                                                            type="password"
                                                            class="form-control"
                                                            id="userpassword"
                                                            placeholder="Enter password"
                                                            :class="{
                                                                'is-invalid':
                                                                    submitted &&
                                                                    $v.password
                                                                        .$error,
                                                            }"
                                                        />
                                                        <div
                                                            v-if="
                                                                submitted &&
                                                                !$v.password
                                                                    .required
                                                            "
                                                            class="invalid-feedback"
                                                        >
                                                            Password is
                                                            required.
                                                        </div>
                                                    </div>

                                                    <!-- <div
                                                        class="custom-control custom-checkbox"
                                                    >
                                                        <input
                                                            type="checkbox"
                                                            class="custom-control-input"
                                                            id="customControlInline"
                                                        />
                                                        <label
                                                            class="custom-control-label"
                                                            for="customControlInline"
                                                            >Remember me</label
                                                        >
                                                    </div> -->

                                                    <div
                                                        class="mt-4 text-center"
                                                    >
                                                        <button
                                                            class="btn btn-primary btn-block w-md waves-effect waves-light"
                                                            type="submit"
                                                        >
                                                            Log In
                                                        </button>
                                                    </div>

                                                    <!-- <div
                                                        class="mt-4 text-center"
                                                    >
                                                        <router-link
                                                            tag="a"
                                                            to="/forgot-password"
                                                            class="text-muted"
                                                        >
                                                            <i
                                                                class="mdi mdi-lock mr-1"
                                                            ></i>
                                                            Forgot your
                                                            password?
                                                        </router-link>
                                                    </div> -->
                                                </form>
                                            </div>
                                            <br />
                                            <br />
                                            <div class="qrcode_row">
                                                <img
                                                    src="../../../assets/images/login/qr-code-android.png"
                                                    alt="android"
                                                    class="qrcode_image"
                                                />
                                                <img
                                                    src="../../../assets/images/login/qr-code-ios.png"
                                                    alt="ios"
                                                    class="qrcode_image"
                                                />
                                            </div>
                                            <div class="qrcode_row">
                                                <img
                                                    src="../../../assets/images/login/googleplay_badge.jpg"
                                                    alt=""
                                                    class="store_image"
                                                />
                                                <img
                                                    src="../../../assets/images/login/app-store-badge.png"
                                                    alt=""
                                                    class="store_image"
                                                />
                                            </div>

                                            <div class="mt-3 text-center">
                                                <p>
                                                    <span>
                                                        <a
                                                            href="/terms-and-conditions"
                                                        >
                                                            Terms and
                                                            Conditions</a
                                                        >
                                                    </span>
                                                    |
                                                    <span>
                                                        <a
                                                            href="/privacy-and-policy"
                                                        >
                                                            Privacy and
                                                            Policy</a
                                                        >
                                                    </span>
                                                </p>
                                                <p>
                                                    © Copyright 2022 Falconcity
                                                    of Wonders.
                                                    <br />
                                                    All Rights Reserved Version
                                                    2
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-8">
                        <div class="authentication-bg">
                            <br />
                            <br />
                            <br />
                            <div class="social_row">
                                <img
                                    src="../../../assets/images/login/FCW-Logo_whitebg_logo.png"
                                    alt=""
                                    class="fcwcs_logo"
                                />
                            </div>
                            <br />
                            <br />

                            <div class="social_row">
                                <button
                                    class="btn btn-light w-md waves-effect waves-light"
                                    type="submit"
                                    @click="redirect('https://falconcity.com')"
                                >
                                    ABOUT US
                                </button>
                                &nbsp;&nbsp;
                                <button
                                    class="btn btn-light w-md waves-effect waves-light"
                                    type="submit"
                                    @click="
                                        redirect(
                                            'https://falconcity.com/contact-us'
                                        )
                                    "
                                >
                                    CONTACT US
                                </button>
                            </div>
                            <br />
                            <br />
                            <div class="iso_row">
                                <img
                                    src="../../../assets/images/login/iso10004.png"
                                    alt=""
                                    class="iso_image"
                                />
                                <img
                                    src="../../../assets/images/login/iso9001.jpg"
                                    alt=""
                                    class="iso_image"
                                />
                                <img
                                    src="../../../assets/images/login/iso10002.png"
                                    alt=""
                                    class="iso_image"
                                />
                            </div>
                            <br />
                            <div class="social_row">
                                <img
                                    src="../../../assets/images/login/Icon feather-facebook@2x.png"
                                    alt=""
                                    class="social_image"
                                />
                                <img
                                    src="../../../assets/images/login/Icon feather-twitter@2x.png"
                                    alt=""
                                    class="social_image"
                                />
                                <img
                                    src="../../../assets/images/login/Icon ionic-logo-youtube@2x.png"
                                    alt=""
                                    class="social_image"
                                />
                                <img
                                    src="../../../assets/images/login/Icon zocial-instagram@2x.png"
                                    alt=""
                                    class="social_image"
                                />
                            </div>
                            <br />
                            <br />
                            <div class="social_row">
                                <img
                                    src="../../../assets/images/login/mastercard.png"
                                    alt=""
                                    class="payment_image"
                                />
                                <img
                                    src="../../../assets/images/login/visa.png"
                                    alt=""
                                    class="payment_image"
                                />
                                <img
                                    src="../../../assets/images/login/mastercard_securecode.png"
                                    alt=""
                                    class="payment_image"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>
.qrcode_row {
    display: flex;
    justify-content: space-evenly;
}
.qrcode_image {
    margin: 5px;
    height: 150px;
    width: 150px;
    object-fit: contain;
}
.store_image {
    margin: 5px;
    height: 50px;
    width: 150px;
    object-fit: contain;
}
.fcwcs_logo {
    height: 150px;
}
.social_row {
    text-align: center;
}
.social_image {
    height: 40px;
    width: 35px;
    margin-right: 10px;
    object-fit: contain;
}
.social_image:hover {
    cursor: pointer;
}
.payment_image {
    margin: 5px;
    height: 40px;
    width: 110px;
    object-fit: contain;
    background-color: white;
    border: 1px solid white;
    border-radius: 10px;
}
.iso_row {
    text-align: center;
}
.iso_image {
    margin: 7px;
    height: 150px;
    width: 150px;
    object-fit: contain;
    border: 1px solid white;
    border-radius: 10px;
}
</style>
